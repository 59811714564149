<template> 
  <v-row class="mt-3">
    <o-subscribe
      v-model="statsData"
      :query="statsSubscription"
      :keys="['dataTableStatus', 'rows']"
    />
    <template v-if="statsData">
      <v-col cols="12">
        <template v-if="statsData">
          <v-row class="ma-0 pa-0">
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6" 
              lg="4"
              xl="2"
              class="ma-0 pa-1"
            >
              <o-widget
                icon="mdi-eye-outline"
                background="warning"
                text="Impression"
                :value="getInteger(statsData.today[0].impressions || 0)"  
                :inner-icon="`mdi-arrow-${statsData.today[0].impressions_up?'up':'down'}-bold-outline`" 
                :inner-icon-color="statsData.today[0].impressions_up?'green--text':'red--text'"
                :sheet-title="getInteger(statsData.today[0].yesterday_impressions || 0)"
                :sheet-text="`${statsData.today[0].percent_impressions > 0 ? '+': ''}${getFloat(statsData.today[0].percent_impressions || 0)}`"
              />
            </v-col>
            
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6" 
              lg="4"
              xl="2"
              class="ma-0 pa-1"
            >
              <o-widget
                icon="mdi-currency-usd"
                background="light-blue darken-1"
                text="Spends"
                :value="`${getCurrency(statsData.today[0].spends || 0)}`"  
                :inner-icon="`mdi-arrow-${statsData.today[0].spends_up?'up':'down'}-bold-outline`" 
                :inner-icon-color="statsData.today[0].spends_up?'green--text':'red--text'"
                :sheet-title="getCurrency(statsData.today[0].yesterday_spends || 0)"
                :sheet-text="`${statsData.today[0].percent_spends > 0 ? '+': ''}${getFloat(statsData.today[0].percent_spends || 0)}`"
              />
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6" 
              lg="4"
              xl="2"
              class="ma-0 pa-1"
            >      
              <o-widget
                icon="mdi-trophy-outline"
                background="blue-grey darken-1"
                text="Wins"
                :value="getInteger(statsData.today[0].wins || 0)" 
                :inner-icon="`mdi-arrow-${statsData.today[0].wins_up?'up':'down'}-bold-outline`" 
                :inner-icon-color="statsData.today[0].wins_up?'green--text':'red--text'"
                :sheet-title="getInteger(statsData.today[0].yesterday_wins || 0)"
                :sheet-text="`${statsData.today[0].percent_wins > 0 ? '+': ''}${getFloat(statsData.today[0].percent_wins || 0)}`"
              />      
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6" 
              lg="4"
              xl="2"
              class="ma-0 pa-1"
            >
              <o-widget
                icon="mdi-video-outline"
                background="green darken-1"
                text="VCR"
                :value="getPercent(statsData.today[0].vcr || 0)" 
                :inner-icon="`mdi-arrow-${statsData.today[0].vcr_up?'up':'down'}-bold-outline`" 
                :inner-icon-color="statsData.today[0].vcr_up?'green--text':'red--text'"
                :sheet-title="getPercent(statsData.today[0].yesterday_vcr || 0)"
                :sheet-text="`${statsData.today[0].percent_vcr > 0 ? '+': ''}${getFloat(statsData.today[0].percent_vcr || 0)}`"
              />
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6" 
              lg="4"
              xl="2"
              class="ma-0 pa-1"
            >
              <o-widget
                icon="mdi-tag-outline"
                background="lime darken-1"
                text="CPM"
                :value="`${getCurrency(statsData.today[0].cpm || 0)}`" 
                :inner-icon="`mdi-arrow-${statsData.today[0].cpm_up?'up':'down'}-bold-outline`" 
                :inner-icon-color="statsData.today[0].cpm_up?'green--text':'red--text'"
                :sheet-title="getCurrency(statsData.today[0].yesterday_cpm || 0)"
                :sheet-text="`${statsData.today[0].percent_cpm > 0 ? '+': ''}${getFloat(statsData.today[0].percent_cpm || 0)}`"
              />
            </v-col>
            
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6" 
              lg="4"
              xl="2"
              class="ma-0 pa-1"
            >
              <o-widget
                icon="mdi-content-copy"
                background="orange darken-1"
                text="Bids"
                :value="getInteger(statsData.today[0].bids || 0)" 
                :inner-icon="`mdi-arrow-${statsData.today[0].bids_up?'up':'down'}-bold-outline`" 
                :inner-icon-color="statsData.today[0].bids_up?'green--text':'red--text'"
                :sheet-title="getInteger(statsData.today[0].yesterday_bids || 0)"
                :sheet-text="`${statsData.today[0].percent_bids > 0 ? '+': ''}${getFloat(statsData.today[0].percent_bids || 0)}`"
              />
            </v-col>
          </v-row>
        </template> 
      </v-col>
      
      <v-col 
        cols="12"
        xs="12"
        sm="12"
        md="12" 
        lg="6"
        xl="6"
      > 
        <div
          tabindex="-1"
          class="pl-0 v-list-item"
        >
          <div
            class="v-avatar v-list-item__avatar"
            style="height: 40px; min-width: 40px; width: 40px;"
          >
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-clock text--lighten-4 grey lighten-4"
            />
          </div>
          <div class="v-list-item__content">
            <div class="v-list-item__title body-2 font-weight-medium">
              UTC
            </div>
            <div class="v-list-item__subtitle">
              {{ statsData.time.admin_utc || ''}}
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12" 
        lg="6"
        xl="6"
      > 
        <div
          tabindex="-1"
          class="pl-0 v-list-item"
        >
          <div
            class="v-avatar v-list-item__avatar"
            style="height: 40px; min-width: 40px; width: 40px;"
          >
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-clock text--lighten-4 grey lighten-4"
            />
          </div>
          <div class="v-list-item__content">
            <div class="v-list-item__title body-2 font-weight-medium">
              EST
            </div>
            <div class="v-list-item__subtitle">
              {{ statsData.time.admin_est || ''}}
            </div>
          </div>
        </div>
      </v-col>

      <v-col 
        cols="12"
        xs="12"
        sm="12"
        md="12" 
        lg="6"
        xl="4"
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0 text-uppercase">
                IMPRESSIONS
              </p>
            </div>
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              ref="hourlyImpressions"
              type="line"
              height="200"
              :options="hourlyImpressions.chartOptions"
              :series="hourlyImpressions.series"
            />
          </v-card-text> 
        </base-card>
      </v-col> 

       <v-col 
        cols="12"
        xs="12"
        sm="12"
        md="12" 
        lg="6"
        xl="4"
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0 text-uppercase">
                SPENDS
              </p>
            </div>
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              ref="hourlySpends"
              type="line"
              height="200"
              :options="hourlySpends.chartOptions"
              :series="hourlySpends.series"
            />
          </v-card-text> 
        </base-card>
      </v-col> 

      <v-col 
        cols="12"
        xs="12"
        sm="12"
        md="12" 
        lg="6"
        xl="4"
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0 text-uppercase">
                CPM
              </p>
            </div>
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              ref="hourlyCpm"
              type="line"
              height="200"
              :options="hourlyCpm.chartOptions"
              :series="hourlyCpm.series"
            />
          </v-card-text> 
        </base-card>
      </v-col> 

      <v-col 
        cols="12"
        xs="12"
        sm="12"
        md="12" 
        lg="6"
        xl="4"
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0 text-uppercase">
                VCR
              </p>
            </div>
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              ref="hourlyVcr"
              type="line"
              height="200"
              :options="hourlyVcr.chartOptions"
              :series="hourlyVcr.series"
            />
          </v-card-text> 
        </base-card>
      </v-col> 

      <v-col 
        cols="12"
        xs="12"
        sm="12"
        md="12" 
        lg="6"
        xl="4"
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0 text-uppercase">
                BIDS
              </p>
            </div>
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              ref="hourlyBids"
              type="line"
              height="200"
              :options="hourlyBids.chartOptions"
              :series="hourlyBids.series"
            />
          </v-card-text> 
        </base-card>
      </v-col> 

      <v-col 
        cols="12"
        xs="12"
        sm="12"
        md="12" 
        lg="6"
        xl="4"
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0 text-uppercase">
                WINS
              </p>
            </div>
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              ref="hourlyWins"
              type="line"
              height="200"
              :options="hourlyWins.chartOptions"
              :series="hourlyWins.series"
            />
          </v-card-text> 
        </base-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="6"
      >
        <base-card style="height:100%"> 
          <v-card-title class="d-flex justify-space-between ">
            <div class="mb-0 mr-4">
              <p class="card-title text-capitalize mb-0">
                Impression Breakdown
              </p>
            </div>
            <v-menu
              left
              bottom
            >
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  color="lighten-3 text-uppercase"
                  small
                  depressed
                  v-bind="attrs"
                  butt 
                  v-on="on"
                >
                  {{ impressionKey }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list style="cursor: pointer">
                <v-list-item>
                  <v-list-item-title @click="impressionKey = 'today'; setImpressionBreakdown('today', statsData);">
                    Today
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="impressionKey = 'yesterday'; setImpressionBreakdown('yesterday', statsData);">
                    Yesterday
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <apexchart
              ref="impressionBreakdown"
              type="pie"
              height="350"
              width="100%"
              :options="impressionBreakdown.chartOptions"
              :series="impressionBreakdown.series"
            />
          </v-card-text>
        </base-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="6"
      >
        <base-card style="height:100%"> 
          <v-card-title class="d-flex justify-space-between ">
            <div class="mb-0 mr-4">
              <p class="card-title text-capitalize mb-0">
                Spend Breakdown
              </p>
            </div>
            <v-menu
              left
              bottom
            >
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  color="lighten-3 text-uppercase"
                  small
                  depressed
                  v-bind="attrs"
                  butt 
                  v-on="on"
                >
                  {{ spendKey }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list style="cursor: pointer">
                <v-list-item>
                  <v-list-item-title @click="spendKey = 'today'; setSpendBreakdown('today', statsData)">
                    Today
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="spendKey = 'yesterday'; setSpendBreakdown('yesterday', statsData)">
                    Yesterday
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <apexchart
              ref="spendBreakdown"
              type="pie"
              height="350"
              width="100%"
              :options="spendBreakdown.chartOptions"
              :series="spendBreakdown.series"
            />
          </v-card-text>
        </base-card>
      </v-col>

 <v-col 
        cols="12"
        xs="12"
        sm="12"
        md="12" 
        lg="6"
        xl="6"
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0 text-uppercase">
                GAINERS
              </p>
            </div>
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              ref="gainers"
              type="bar"
              height="250"
              :options="gainers.chartOptions"
              :series="gainers.series"
            />
          </v-card-text> 
        </base-card>
      </v-col>  

      <v-col 
        cols="12"
        xs="12"
        sm="12"
        md="12" 
        lg="6"
        xl="6"
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0 text-uppercase">
                LOSERS
              </p>
            </div>
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              ref="losers"
              type="bar"
              height="250"
              :options="losers.chartOptions"
              :series="losers.series"
            />
          </v-card-text> 
        </base-card>
      </v-col>  

      <v-col
        cols="12" 
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0">
                Hour Trend
              </p>
            </div>            
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              ref="hourTrend"
              type="area"
              height="320"
              :options="hourTrend.chartOptions"
              :series="hourTrend.series"
            />
          </v-card-text> 
        </base-card>
      </v-col>

      <v-col
        cols="12" 
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0">
                Daily Trend
              </p>
            </div>            
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              ref="dailyTrend"
              type="area"
              height="320"
              :options="dailyTrend.chartOptions"
              :series="dailyTrend.series"
            />
          </v-card-text> 
        </base-card>
      </v-col>

      <v-col
        cols="12" 
      >
        <base-card> 
          <v-card-title class="d-flex justify-space-between">
            <div class="mb-0 mr-4">
              <p class="card-title mb-0">
                Monthly Active Trend (Advertiser / Campaign / Line Item)
              </p>
            </div> 
          </v-card-title>
          <v-divider />
        
          <v-card-text>
            <apexchart
              height="250"
              :options="getMonthsChart().chartOptions"
              :series="getMonthsChart().series"
            />
          </v-card-text> 
        </base-card>
      </v-col>

      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <base-card style="height:100%"> 
          <v-card-title class="d-flex">
            <div class="card-title text-capitalize mb-0">{{campaign_sort}} Campaign Performance</div> 
            <div class="ml-auto p-2">
              <o-button
                :color="(campaign_sort === 'top' ? 'red' : 'green')"
                :icon="(campaign_sort === 'top' ? 'mdi-arrow-down-bold' : 'mdi-arrow-up-bold')"
                type="label-icon"
                :label="(campaign_sort === 'top' ? 'bottom' : 'top')"
                @on-click="campaign_sort = (campaign_sort === 'top' ? 'bottom' : 'top')"
              /> 
            </div>
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <o-table
              ref="table" 
              type="array"
              result-type="straight"   
              :fixed-header="true"
              :no-count="true"
              :data="getTopCampaigns()"
              :headers="[
                { text: 'ID', align: 'start', sortable: false, value: 'campaign_id', type: 'text'},
                { text: 'Name', align: 'start', sortable: false, value: 'campaign_name', type: 'text'},
                { text: 'Impressions', align: 'end', sortable: false, value: 'impressions', type: 'int'},
                { text: 'Spends', align: 'end', sortable: false, value: 'spends', type: 'currency'},              
                { text: 'CPM', align: 'end', sortable: false, value: 'cpm', type: 'currency'},
                { text: 'VCR', align: 'end', sortable: false, value: 'vcr', type: 'percent'},
                { text: 'Bids', align:'end', value: 'bids', type: 'int'},
                { text: 'Wins', align:'end', value: 'wins', type: 'int'},
                { value: 'action', type: 'action', 
                  actions: [
                    {key: 'on-item-edit', label: 'Campaign Details', icon: 'mdi-format-list-bulleted-square', color: 'success'},                                
                  ]
                },
              ]"
              @on-item-edit="campaignDetail"
            />
          </v-card-text>
        </base-card>
      </v-col>

      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <base-card style="height:100%"> 
          <v-card-title class="d-flex">
            <div class="card-title text-capitalize mb-0">{{line_item_sort}} Line Item Performance</div> 
            <div class="ml-auto p-2">
              <o-button
                :color="(line_item_sort === 'top' ? 'red' : 'green')"
                :icon="(line_item_sort === 'top' ? 'mdi-arrow-down-bold' : 'mdi-arrow-up-bold')"
                type="label-icon"
                :label="(line_item_sort === 'top' ? 'bottom' : 'top')"
                @on-click="line_item_sort = (line_item_sort === 'top' ? 'bottom' : 'top')"
              /> 
            </div>
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <o-table
              ref="table" 
              type="array"
              result-type="straight"   
              :fixed-header="true"
              :no-count="true"
              :data="getTopLineItems()"
              :headers="[
                { text: 'ID', align: 'start', sortable: false, value: 'line_item_id', type: 'text'},
                { text: 'Name', align: 'start', sortable: false, value: 'line_item_name', type: 'text'},
                { text: 'Impressions', align: 'end', sortable: false, value: 'impressions', type: 'int'},
                { text: 'Spends', align: 'end', sortable: false, value: 'spends', type: 'currency'},
                { text: 'CPM', align: 'end', sortable: false, value: 'cpm', type: 'currency'},         
                { text: 'VCR', align: 'end', sortable: false, value: 'vcr', type: 'percent'},
                { text: 'Bids', align:'end', value: 'bids', type: 'int'},
                { text: 'Wins', align:'end', value: 'wins', type: 'int'},                
                { value: 'action', type: 'action', 
                  actions: [
                    {key: 'on-item-edit', label: 'Line Item Details', icon: 'mdi-format-list-bulleted-square', color: 'success'},
                  ]
                },
              ]"
              @on-item-edit="lineItemDetail"
            />
          </v-card-text>
        </base-card>
      </v-col>
    </template>
  </v-row> 
</template>
<script> 
import RestApi from '@/services/RestApi' 
import moment from "moment";
export default {
  name: 'DashboardPerformance',
  mixins: [RestApi],
  metaInfo: {
    title: 'Performance',
  },  
  data() {
    return {
      statsSubscription: `
        subscription {
          dataTableStatus (id: "performance_dashboard") {
            id, rows
          } 
        }`,
      statsData: null,
      hourKey: 'wins',
      monthHourKey: 'impressions',
      impressionKey: 'today',
      spendKey: 'today',  
      hourlyImpressions: { chartOptions: {}, series: [] },
      hourlySpends: { chartOptions: {}, series: [] },
      hourlyCpm: { chartOptions: {}, series: [] },
      hourlyVcr: { chartOptions: {}, series: [] },
      hourlyBids: { chartOptions: {}, series: [] },
      hourlyWins: { chartOptions: {}, series: [] },
      impressionBreakdown: { chartOptions: {}, series: [] },
      spendBreakdown: { chartOptions: {}, series: [] },
      hourTrend: { chartOptions: {}, series: [] },
      dailyTrend: { chartOptions: {}, series: [] },
      gainers: { chartOptions: {}, series: [] },
      losers: { chartOptions: {}, series: [] },
      campaign_sort: 'bottom',
      line_item_sort: 'bottom'
    }
  },
  computed: { 
  },  
  watch: {
    statsData(newValue) {
      setTimeout(async () => {
        this.setHourly('hourlyImpressions', 'impressions', newValue);
        this.setHourly('hourlySpends', 'spends', newValue);
        this.setHourly('hourlyCpm', 'cpm', newValue);
        this.setHourly('hourlyVcr', 'vcr', newValue);
        this.setHourly('hourlyBids', 'bids', newValue);
        this.setHourly('hourlyWins', 'wins', newValue);
        this.setImpressionBreakdown(this.impressionKey, newValue);
        this.setSpendBreakdown(this.spendKey, newValue);
        this.setHourTrend(newValue);
        this.setDailyTrend(newValue);
        this.setGainers(newValue);
        this.setLosers(newValue);
        
      },
      2000);
    },
    campaign_sort(newValue) {
      this.getTopCampaigns();
    },
    line_item_sort(newValue) {
      this.getTopLineItems();
    }
  }, 
  mounted() {
    const hourlyConfig = _.cloneDeep(this.lineChartConfig)
 
    hourlyConfig.series.push(
      {
        name: 'Yesterday',
        type: 'column',
        data: [
          null, null, null, null, null, null, null, null, null, null, null, null,
          null, null, null, null, null, null, null, null, null, null, null, null
        ]
      },
      {
        name: 'Today',
        type: 'line',
        data: [
          null, null, null, null, null, null, null, null, null, null, null, null,
          null, null, null, null, null, null, null, null, null, null, null, null
        ]
      }
    ); 
    
    hourlyConfig.chartOptions.theme = { mode: this.$vuetify.theme.isDark ? 'dark' : 'light' };
    hourlyConfig.chartOptions.chart.background = 'transparent';
    hourlyConfig.chartOptions.colors = ['#2CD9C5', '#FF6C40'];
    hourlyConfig.chartOptions.yaxis.labels = {
      formatter: function (value, chart) {
        let ret = value;
        if (ret && ret != 0 && chart && chart.w) {

          if (['spends', 'cpm'].includes(chart.w.config.hourKey)) {
            return chart.w.config.parentControl.getCurrency(value);
            //return  parseFloat(parseFloat(value).toFixed(2)).toLocaleString() || '0.00';
          } else if (['vcr'].includes(chart.w.config.hourKey)) {
            return  `${parseFloat(parseFloat(value).toFixed(2)).toLocaleString()}%` || '0.00%';              
          } else {
            return parseInt(value.toString()).toLocaleString() || '0'; 
          } 
        }
        if (ret > 0) {
          return parseInt(value.toString()).toLocaleString() || '0'; 
        }
        return ret;
      }, 
    };  
    hourlyConfig.chartOptions.xaxis.title.text = 'HOUR';
    hourlyConfig.chartOptions.xaxis.categories = [
      0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23
    ];

    this.hourlyImpressions = _.cloneDeep(hourlyConfig);
    this.hourlyImpressions.chartOptions.hourKey = 'impressions';
    this.hourlyImpressions.chartOptions.parentControl = this;
    this.hourlySpends = _.cloneDeep(hourlyConfig);
    this.hourlySpends.chartOptions.hourKey = 'spends';
    this.hourlySpends.chartOptions.parentControl = this;
    this.hourlyCpm = _.cloneDeep(hourlyConfig);
    this.hourlyCpm.chartOptions.hourKey = 'cpm';
    this.hourlyCpm.chartOptions.parentControl = this;
    this.hourlyVcr = _.cloneDeep(hourlyConfig);
    this.hourlyVcr.chartOptions.hourKey = 'vcr';
    this.hourlyVcr.chartOptions.parentControl = this;
    this.hourlyBids = _.cloneDeep(hourlyConfig);
    this.hourlyBids.chartOptions.hourKey = 'bids';
    this.hourlyBids.chartOptions.parentControl = this;
    this.hourlyWins = _.cloneDeep(hourlyConfig);
    this.hourlyWins.chartOptions.hourKey = 'winds';
    this.hourlyWins.chartOptions.parentControl = this;
  
    this.impressionBreakdown = _.cloneDeep(this.pieChartConfig);
    this.impressionBreakdown.chartOptions.parentControl = this;
    this.impressionBreakdown.chartOptions.tooltip = {
      y: {
        formatter: function (value, chart) {          
          return `${chart.config.parentControl.getInteger(value)}`
        }
      }      
    }
    this.spendBreakdown = _.cloneDeep(this.pieChartConfig)   
    this.spendBreakdown.chartOptions.parentControl = this;
    this.spendBreakdown.chartOptions.tooltip = {
      y: {
        formatter: function (value, chart) {          
          return `${chart.config.parentControl.getCurrency(value)}`
        }
      }      
    }

    this.hourTrend = _.cloneDeep(this.customChartConfig); 
    this.hourTrend.chartOptions.parent = this;
    this.hourTrend.chartOptions.markers = {
        size: 0,
        style: 'hollow',
      }
    this.hourTrend.chartOptions.chart.toolbar = true;
    this.hourTrend.chartOptions.xaxis = {
        type: 'datetime', 
        tickAmount: 6,
        categories: []
    }
    this.hourTrend.chartOptions.tooltip = {
      x: {
        format: 'dd MMM yyyy HH:00',
      },
    }    
    this.hourTrend.series = [
      {
        name: 'Impressions',
        type: 'line',
        data: []
      }, 
      {
        name: 'Spends',
        type: 'line',
        data: []
      }, 
      {
        name: 'CPM',
        type: 'line',
        data: []
      },
      {
        name: 'VCR',
        type: 'line',
        data: []
      },
      {
        name: 'Bids',
        type: 'line',
        data: []
      },
      {
        name: 'Wins',
        type: 'line',
        data: []
      }
    ];         
    this.hourTrend.chartOptions.yaxis = [ 
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#5d78ff',
        }, 
        title: {
          text: 'IMPRESSIONS',
          style: {
            color: '#5d78ff',
          },
        },
        labels: { 
          style: {
            color: '#5d78ff',
          },
          formatter: function (value, chart) {
            let ret = value
            if (ret > 0) {
              return parseInt(value.toString()).toLocaleString() || '0'
            }
            return ret
          }, 
        }
      },
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#fbaf0f',
        }, 
        title: {
          text: 'SPENDS', 
          style: {
            color: '#fbaf0f',
          },
        },
        labels: { 
          style: {
            color: '#fbaf0f',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getCurrency(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#ff5252',
        }, 
        title: {
          text: 'CPM',
          style: {
            color: '#ff5252',
          },
        },
        labels: { 
          style: {
            color: '#ff5252',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getCurrency(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FF8A48',
        }, 
        title: {
          text: 'VCR',
          style: {
            color: '#FF8A48',
          },
        },
        labels: { 
          style: {
            color: '#FF8A48',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getPercent(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#304156',
        }, 
        title: {
          text: 'BIDS',
          style: {
            color: '#304156',
          },
        },
        labels: { 
          style: {
            color: '#304156',
          }, 
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getInteger(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#09B66D',
        }, 
        title: {
          text: 'WINS',
          style: {
            color: '#09B66D',
          },
        },
        labels: { 
          style: {
            color: '#09B66D',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getInteger(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
    
    ]

    this.dailyTrend = _.cloneDeep(this.customChartConfig);
    this.dailyTrend.chartOptions.parent = this;
    this.dailyTrend.chartOptions.markers = {
        size: 0,
        style: 'hollow',
      }
    this.dailyTrend.chartOptions.chart.toolbar = true; 
    this.dailyTrend.chartOptions.tooltip = {
      x: {
        format: 'dd MMM yyyy',
      },
    }
    this.dailyTrend.series = [
      {
        name: 'Impressions',
        type: 'line',
        data: []
      }, 
      {
        name: 'Spends',
        type: 'line',
        data: []
      }, 
      {
        name: 'CPM',
        type: 'line',
        data: []
      },
      {
        name: 'VCR',
        type: 'line',
        data: []
      },
      {
        name: 'Bids',
        type: 'line',
        data: []
      },
      {
        name: 'Wins',
        type: 'line',
        data: []
      }
    ];
    this.dailyTrend.chartOptions.yaxis = [ 
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#5d78ff',
        }, 
        title: {
          text: 'IMPRESSIONS',
          style: {
            color: '#5d78ff',
          },
        },
        labels: { 
          style: {
            color: '#5d78ff',
          },
          formatter: function (value, chart) {
            let ret = value
            if (ret > 0) {
              return parseInt(value.toString()).toLocaleString() || '0'
            }
            return ret
          }, 
        }
      },
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#fbaf0f',
        }, 
        title: {
          text: 'SPENDS', 
          style: {
            color: '#fbaf0f',
          },
        },
        labels: { 
          style: {
            color: '#fbaf0f',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getCurrency(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: false,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#ff5252',
        }, 
        title: {
          text: 'CPM',
          style: {
            color: '#ff5252',
          },
        },
        labels: { 
          style: {
            color: '#ff5252',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getCurrency(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FF8A48',
        }, 
        title: {
          text: 'VCR',
          style: {
            color: '#FF8A48',
          },
        },
        labels: { 
          style: {
            color: '#FF8A48',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getPercent(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#304156',
        }, 
        title: {
          text: 'BIDS',
          style: {
            color: '#304156',
          },
        },
        labels: { 
          style: {
            color: '#304156',
          }, 
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getInteger(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
      { 
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#09B66D',
        }, 
        title: {
          text: 'WINS',
          style: {
            color: '#09B66D',
          },
        },
        labels: { 
          style: {
            color: '#09B66D',
          },
          formatter: function (value, chart) {
            if (chart && chart.w && chart.w.config && chart.w.config.parent) {
              return chart.w.config.parent.getInteger(value);
            } else { 
              if (value > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return value
            }
          }, 
        }
      },
    
    ]

    this.gainers.series = [];
    this.gainers.chartOptions.parent = this;
    this.gainers.chartOptions.xaxis = {
      categories: []
    };
        
    this.gainers.chartOptions.yaxis = { 
      axisTicks: {
        show: true
      }, 
    };
    this.gainers.chartOptions.plotOptions = {
      bar: {        
        distributed: true,
        horizontal: true
      }
    };
    this.gainers.chartOptions.colors = ['#55fa0c', '#50e614', '#47bf25', '#3d9736', '#347047'],
    this.gainers.chartOptions.legend = {
      show: false
    }
    this.gainers.chartOptions.dataLabels = { 
      enabled: true, 
      formatter: function (value, chart) {          
        return `${chart.w.config.parent.getPercent(value)}`
      },      
      textAnchor: 'start',
      style: {
        colors: ['#fff']
      },
      offsetX: 0,
      dropShadow: {
        enabled: true
      }
    }
    this.gainers.chartOptions.tooltip = {
      y: {
        formatter: function (value, chart) {          
          return `${chart.w.config.parent.getPercent(value)}`
        }
      }      
    } 

    this.losers.series = [];
    this.losers.chartOptions.parent = this;
    this.losers.chartOptions.xaxis = {
      categories: []
    };
        
    this.losers.chartOptions.yaxis = { 
      //reversed: false,
      axisTicks: {
        show: true
      }
    };
    this.losers.chartOptions.plotOptions = {
      bar: {        
        distributed: true,
        horizontal: true
      }
    };
    this.losers.chartOptions.colors = ['#fa0c44', '#e51246', '#d01848', '#c61b49', '#b1214b'],
    this.losers.chartOptions.legend = {
      show: false
    }
    this.losers.chartOptions.dataLabels = { 
      enabled: true,
      formatter: function (value, chart) {          
        return `${chart.w.config.parent.getPercent(value)}`
      },
      textAnchor: 'start',
      style: {
        colors: ['#fff']
      },
      offsetX: 0,
      dropShadow: {
        enabled: true
      }
    }
    this.losers.chartOptions.tooltip = {
      y: {
        formatter: function (value, chart) {          
          return `${chart.w.config.parent.getPercent(value)}`
        }
      }      
    } 
  },
  methods: { 
    setHourly(ctrl, key, value) {
      const today = value.today_hour;
      const yesterday = value.yesterday_hour;

      const series = [
        {
          name: 'Yesterday',
          type: 'column',
          data: yesterday.map((d) => d[key] > 0 ? d[key] : null)
        },
        {
          name: 'Today',
          type: 'line',
          data: today.map((d) => d[key] > 0 ? d[key] : null)
        }
      ];
      if (this.$refs[ctrl]) {
        this.$refs[ctrl].updateSeries(series, true);       
      }
    },
    setImpressionBreakdown(key, value) {
      const labels = [];
      const series = [];

      const data = value[`${key}_advertiser_impressions`];

      data.forEach((d) => {
        labels.push(d.advertiser_name ? d.advertiser_name.toUpperCase() : '');
        series.push(d.impressions);
      });       

      if (this.$refs.impressionBreakdown) {
        this.$refs.impressionBreakdown.updateSeries(series, true);
        this.$refs.impressionBreakdown.updateOptions({labels}, false, true);
        
      }
    },
    setSpendBreakdown(key, value) {
      const labels = [];
      const series = [];

      const data = value[`${key}_advertiser_spends`];

      data.forEach((d) => {
        labels.push(d.advertiser_name ? d.advertiser_name.toUpperCase() : '');
        series.push(d.spends);
      });       

      if (this.$refs.spendBreakdown) {
        this.$refs.spendBreakdown.updateSeries(series, true);
        this.$refs.spendBreakdown.updateOptions({labels}, false, true);
      }
    },
    setHourTrend(value) {
      const monthly = value.month_hour;
      const xaxis = {
        type: 'datetime', 
        tickAmount: 6,
        categories: monthly.map((d) => {          
          const date = new Date(d.bid_date)           
          return moment(date).format('DD MMM YYYY HH:00')
        })
      }
      const series = []; 
      series.push(
        {
          name: 'Impressions',
          type: 'line',
          data: monthly.map((d) => d.impressions)
        }, 
        {
          name: 'Spends',
          type: 'line',
          data: monthly.map((d) => d.spends)
        }, 
        {
          name: 'CPM',
          type: 'line',
          data: monthly.map((d) => d.cpm)
        },
        {
          name: 'VCR',
          type: 'line',
          data: monthly.map((d) => d.vcr)
        },
        {
          name: 'Bids',
          type: 'line',
          data: monthly.map((d) => d.bids)
        },
        {
          name: 'Wins',
          type: 'line',
          data: monthly.map((d) => d.wins)
        }
      );    
      
      if (this.$refs.hourTrend) {
        this.$refs.hourTrend.updateOptions({xaxis}, false, true);
        this.$refs.hourTrend.updateSeries(series, true);
      }
    },
    setDailyTrend(value) {
      const monthly = value.month_day;
      const xaxis = {
        type: 'datetime', 
        tickAmount: 6,
        categories: monthly.map((d) => {          
          const date = new Date(d.bid_date)           
          return moment(date).format('DD MMM YYYY')
        })
      }
      const series = []; 
      series.push(
        {
          name: 'Impressions',
          type: 'line',
          data: monthly.map((d) => d.impressions)
        }, 
        {
          name: 'Spends',
          type: 'line',
          data: monthly.map((d) => d.spends)
        }, 
        {
          name: 'CPM',
          type: 'line',
          data: monthly.map((d) => d.cpm)
        },
        {
          name: 'VCR',
          type: 'line',
          data: monthly.map((d) => d.vcr)
        },
        {
          name: 'Bids',
          type: 'line',
          data: monthly.map((d) => d.bids)
        },
        {
          name: 'Wins',
          type: 'line',
          data: monthly.map((d) => d.wins)
        }
      );    
      
      if (this.$refs.dailyTrend) {
        this.$refs.dailyTrend.updateOptions({xaxis}, false, true);
        this.$refs.dailyTrend.updateSeries(series, true);
      }
    },
    setGainers(value) {
      const labels = [];
      const series = [];

      const data = value[`gainers_losers`];

      const gainers = data.filter((d) => d.percentage > 0).sort((a, b)=> (a.percentage < b.percentage ? 1 : -1));  

      gainers.forEach((d, i) => {
        if (i < 5) {
          labels.push(d.advertiser_name ? d.advertiser_name.toUpperCase() : '');
          series.push(this.getFloat(d.percentage));
        }
      });      

      if (this.$refs.gainers) {
        this.$refs.gainers.updateSeries([{data: series, name: `Gain`}], true);
        this.$refs.gainers.updateOptions({
            xaxis: {
              categories : labels
            }
          }, false, true);
      }
    },
    setLosers(value) {
      const labels = [];
      const series = [];

      const data = value[`gainers_losers`];

      const losers = data.filter((d) => d.percentage < 0).sort((a, b)=> (a.percentage > b.percentage ? 1 : -1));  

      losers.forEach((d, i) => {
        if (i < 5) {
          labels.push(d.advertiser_name ? d.advertiser_name.toUpperCase() : '');
          series.push(this.getFloat(d.percentage));
        }
      });      

      if (this.$refs.losers) {
        this.$refs.losers.updateSeries([{data: series, name: `Loser`}], true);
        this.$refs.losers.updateOptions({
            xaxis: {
              categories : labels
            }
          }, false, true);
      }
    },
    getDayMonthsChart() {
      if (!this.statsData && !this.statsData.month_day) { 
        return { chartOptions: {}, series: [] }
      } 
      
      const out = _.cloneDeep(this.customChartConfig);
      const monthly = this.statsData.month_day;
      out.chartOptions.parent = this;

      out.chartOptions.markers = {
          size: 0,
          style: 'hollow',
        }
      out.chartOptions.chart.toolbar = true;
      out.chartOptions.xaxis = {
          type: 'date', 
          tickAmount: 6,
          categories: monthly.map((d) => {          
            const date = new Date(d.bid_date)           
            return moment(date).format('DD MMM YYYY')
          })
      }
      out.chartOptions.tooltip = {
        x: {
          format: 'dd MMM yyyy',
        },
      }
      
      out.series.push(
        {
          name: 'Impressions',
          type: 'line',
          data: monthly.map((d) => d.impressions)
        }, 
        {
          name: 'Spends',
          type: 'line',
          data: monthly.map((d) => d.spends)
        }, 
        {
          name: 'CPM',
          type: 'line',
          data: monthly.map((d) => d.cpm)
        },
        {
          name: 'VCR',
          type: 'line',
          data: monthly.map((d) => d.vcr)
        },
        {
          name: 'Bids',
          type: 'line',
          data: monthly.map((d) => d.bids)
        },
        {
          name: 'Wins',
          type: 'line',
          data: monthly.map((d) => d.wins)
        }
      );         

      out.chartOptions.yaxis = [ 
        { 
          opposite: false,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#5d78ff',
          }, 
          title: {
            text: 'IMPRESSIONS',
            style: {
              color: '#5d78ff',
            },
          },
          labels: { 
            style: {
              color: '#5d78ff',
            },
            formatter: function (value, chart) {
              let ret = value
              if (ret > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return ret
            }, 
          }
        },
        { 
          opposite: false,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#fbaf0f',
          }, 
          title: {
            text: 'SPENDS', 
            style: {
              color: '#fbaf0f',
            },
          },
          labels: { 
            style: {
              color: '#fbaf0f',
            },
            formatter: function (value, chart) {
              if (chart && chart.w && chart.w.config && chart.w.config.parent) {
                return chart.w.config.parent.getCurrency(value);
              } else { 
                if (value > 0) {
                  return parseInt(value.toString()).toLocaleString() || '0'
                }
                return value
              }
            }, 
          }
        },
        { 
          opposite: false,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#ff5252',
          }, 
          title: {
            text: 'CPM',
            style: {
              color: '#ff5252',
            },
          },
          labels: { 
            style: {
              color: '#ff5252',
            },
            formatter: function (value, chart) {
              if (chart && chart.w && chart.w.config && chart.w.config.parent) {
                return chart.w.config.parent.getCurrency(value);
              } else { 
                if (value > 0) {
                  return parseInt(value.toString()).toLocaleString() || '0'
                }
                return value
              }
            }, 
          }
        },
        { 
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#FF8A48',
          }, 
          title: {
            text: 'VCR',
            style: {
              color: '#FF8A48',
            },
          },
          labels: { 
            style: {
              color: '#FF8A48',
            },
            formatter: function (value, chart) {
              if (chart && chart.w && chart.w.config && chart.w.config.parent) {
                return chart.w.config.parent.getPercent(value);
              } else { 
                if (value > 0) {
                  return parseInt(value.toString()).toLocaleString() || '0'
                }
                return value
              }
            }, 
          }
        },
        { 
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#304156',
          }, 
          title: {
            text: 'BIDS',
            style: {
              color: '#304156',
            },
          },
          labels: { 
            style: {
              color: '#304156',
            }, 
            formatter: function (value, chart) {
              if (chart && chart.w && chart.w.config && chart.w.config.parent) {
                return chart.w.config.parent.getInteger(value);
              } else { 
                if (value > 0) {
                  return parseInt(value.toString()).toLocaleString() || '0'
                }
                return value
              }
            }, 
          }
        },
        { 
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#09B66D',
          }, 
          title: {
            text: 'WINS',
            style: {
              color: '#09B66D',
            },
          },
          labels: { 
            style: {
              color: '#09B66D',
            },
            formatter: function (value, chart) {
              if (chart && chart.w && chart.w.config && chart.w.config.parent) {
                return chart.w.config.parent.getInteger(value);
              } else { 
                if (value > 0) {
                  return parseInt(value.toString()).toLocaleString() || '0'
                }
                return value
              }
            }, 
          }
        },
      
      ]
      return out
    }, 
    getMonthsChart() {
      
      if (!this.statsData) { 
        return { chartOptions: {}, series: [] }
      } 
      
      const out = _.cloneDeep(this.customChartConfig);
      const monthly = this.statsData.monthly;

      out.series.push(
        {
          name: 'Advertisers',
          type: 'column',
          data: monthly.map((d) => d.advertiser_count)
        }, 
        {
          name: 'Campaigns',
          type: 'column',
          data: monthly.map((d) => d.campaign_count)
        }, 
        {
          type: 'line',
          name: 'Line Items',
          data: monthly.map((d) => d.line_item_count)
        }
      ); 
      out.chartOptions.monthHourKey = this.monthHourKey; 
      out.chartOptions.xaxis.categories = monthly.map((d) => {
        const date = new Date(`${d.bid_month.substr(0,4)}-${d.bid_month.substr(4,2)}-01`)
        return moment(date).format('MMM YYYY')
      });  

      out.chartOptions.yaxis = [ 
        { 
          opposite: false,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#5d78ff',
          }, 
          title: {
            text: 'ADVERTISERS',
            style: {
              color: '#5d78ff',
            },
          },
          labels: { 
            style: {
              color: '#5d78ff',
            },
            formatter: function (value, chart) {
              let ret = value
              if (ret > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return ret
            }, 
          }
        },
        { 
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#fbaf0f',
          }, 
          title: {
            text: 'CAMPAIGNS', 
            style: {
              color: '#fbaf0f',
            },
          },
          labels: { 
            style: {
              color: '#fbaf0f',
            },
            formatter: function (value, chart) {
              let ret = value
              if (ret > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return ret
            }, 
          }
        },
        { 
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#ff5252',
          }, 
          title: {
            text: 'LINE ITEMS',
            style: {
              color: '#ff5252',
            },
          },
          labels: { 
            style: {
              color: '#ff5252',
            },
            formatter: function (value, chart) {
              let ret = value
              if (ret > 0) {
                return parseInt(value.toString()).toLocaleString() || '0'
              }
              return ret
            }, 
          }
        },
       
      ]
      return out
    }, 
    getTopCampaigns() {
      if (!this.statsData) { 
        return [];
      } 
      if (this.campaign_sort == 'top') {
        if (!this.statsData.top_campaigns) { 
          return [];
        } 
        return this.statsData.top_campaigns;
      }
      if (this.campaign_sort == 'bottom') {
        if (!this.statsData.bottom_campaigns) { 
          return [];
        } 
        return this.statsData.bottom_campaigns;
      }
    },
    getTopLineItems() {
      if (!this.statsData) { 
        return [];
      } 
      if (this.line_item_sort == 'top') {
        if (!this.statsData.top_line_items) { 
          return [];
        } 
        return this.statsData.top_line_items;
      }
      if (this.line_item_sort == 'bottom') {
        if (!this.statsData.bottom_line_items) { 
          return [];
        } 
        return this.statsData.bottom_line_items;
      }
    }, 
    lineItemDetail(item) {
      const li_id = item.line_item_id
      this.$router.push(`/app/checker/line-items?line_item_id=${li_id}`);
    },
    campaignDetail(item) {
      const ca_id = item.campaign_id
      this.$router.push(`/app/checker/campaigns?campaign_id=${ca_id}`);
    },
  }
}
</script>
<style scoped>
  .cursor-pointer {
    cursor: pointer;
  }
</style>
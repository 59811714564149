<template>
  <DashboardPerformance dialog-name="Performance" />
</template>
<script>
import DashboardPerformance from '@/views/app/dashboard/Performance'

export default {
  name: 'PerformanceWrapper',
  components: {
    DashboardPerformance
  }
}
</script>
